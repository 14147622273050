import React, { MouseEventHandler, useMemo } from 'react';
import classNames from 'classnames';
import { Box, Card, Stack, Typography } from '@mui/material';

import { CameraInsightsIcon } from 'components/Icons/CameraInsightsIcon';
import CamPlayerV2 from 'components/CamPlayerV2';
import { AutoplayOptions, PlayerSkins } from 'components/CamPlayerV2/constants';
import CamStill from 'components/CamStill';
import CurrentWaveHeightAndRating, { Size } from 'components/CurrentWaveHeightAndRating';
import CurrentWaveRating from 'components/CurrentWaveRating';
import SpotSatelliteWithPin from 'components/SpotSatelliteWithPin';
import WavetrakLink from 'components/WavetrakLink';
import { useMaxWidthMobileMedium } from 'hooks/useMediaQueries';
import { SL_WEB_CAM_PAYWALL_REDESIGN, SL_WEB_OBSERVATION_CLARITY } from 'common/treatments';
import { useTreatments } from 'utils/treatments';
import ConditionObservation from 'components/ConditionObservation';
import { mapDegreesCardinal } from '@surfline/web-common';
import { ConditionObservers } from 'types/observations';
import { roundSurfHeight } from 'utils/roundUnits';
import { useUserPermissionStatus } from 'selectors/user';
import type { Camera } from 'types/camera';
import type { SpotPreviewCardData } from 'types/cards';
import conditionIsFlat from 'utils/conditionIsFlat';
import type { ObservationType } from 'types/surf';

import styles from './SpotPreviewCard.module.scss';

type ObservedSurf = {
  by: ConditionObservers;
  iconUrl?: string;
  at: number | null;
};

type ObservedWind = {
  value: string;
  by: ConditionObservers;
  at: number | null;
};

export interface SpotPreviewCardProps extends SpotPreviewCardData {
  alwaysDisplaySmall?: boolean;
  autoplay?: boolean;
  cardIndex: number;
  className?: string;
  hideThumbnailOnMobile?: boolean;
  href?: string;
  insightsCameraId?: null | string | boolean;
  isMobileView: boolean;
  onClickHandler?: MouseEventHandler;
  showCamInsightsIconAtSmallWidth?: boolean;
  sideThumbnail?: boolean;
  stopStream?: boolean;
  timezone?: string;
  useClarityComponents?: boolean;
}

const SpotPreviewCard: React.FC<SpotPreviewCardProps> = ({
  alwaysDisplaySmall,
  camera,
  cardIndex,
  className,
  clipPathIds,
  currentConditions,
  currentWaveHeight,
  geo,
  hideThumbnailOnMobile = false,
  href,
  id,
  insightsCameraId = null,
  isMobileView,
  onClickHandler,
  showCamInsightsIconAtSmallWidth = false,
  sideThumbnail = false,
  stopStream = false,
  title,
  units,
  currentWind,
  timezone = 'America/Los_Angeles',
  useClarityComponents = false,
}) => {
  const treatments = useTreatments();
  const camPaywallRedesignTreatment = treatments[SL_WEB_CAM_PAYWALL_REDESIGN];

  const isUserInObservationClaritySplit = treatments[SL_WEB_OBSERVATION_CLARITY] === 'on';
  const isObservationClarityEnabled = isUserInObservationClaritySplit && useClarityComponents;

  const isFlat = useMemo(
    () => conditionIsFlat(currentWaveHeight.max, units?.waveHeight),
    [currentWaveHeight, units],
  );

  const observedSurfValue = useMemo(() => {
    if (isFlat) return 'Flat';
    return `${roundSurfHeight(currentWaveHeight.min, units?.waveHeight || 'ft')}-${roundSurfHeight(
      currentWaveHeight.max,
      units?.waveHeight || 'ft',
    )}`;
  }, [currentWaveHeight, isFlat, units]);

  const observedSurf = useMemo<ObservedSurf>(() => {
    let observedSurfObj: ObservedSurf = {
      by: 'LOTUS',
      iconUrl: undefined,
      at: null,
    };

    if (currentWaveHeight.type && (currentWaveHeight.type as ObservationType) === 'CAMERA') {
      observedSurfObj = { ...observedSurfObj, by: 'Smart Cam' };
    }

    if (currentWaveHeight.type && (currentWaveHeight.type as ObservationType) === 'HUMAN') {
      observedSurfObj = { ...observedSurfObj, by: 'Forecaster' };
    }

    if (typeof currentWaveHeight.forecaster === 'object') {
      if (currentWaveHeight.forecaster?.iconUrl) {
        observedSurfObj = {
          ...observedSurfObj,
          iconUrl: currentWaveHeight.forecaster.iconUrl as string,
        };
      }
    }

    if (currentWaveHeight.lastObserved) {
      observedSurfObj = { ...observedSurfObj, at: currentWaveHeight.lastObserved };
    }

    return observedSurfObj;
  }, [currentWaveHeight.forecaster, currentWaveHeight.lastObserved, currentWaveHeight.type]);

  const observedWind = useMemo<ObservedWind>(() => {
    let observedWindObj: ObservedWind = {
      value: '0',
      by: 'GFS',
      at: null,
    };

    if (currentWind) {
      observedWindObj = { ...observedWindObj, value: currentWind.speed.toString() };
    }

    if (currentWind?.lastObserved) {
      observedWindObj = { ...observedWindObj, by: 'Wind Station' };
    }

    if (currentWind?.lastObserved) {
      observedWindObj = { ...observedWindObj, at: currentWind.lastObserved };
    }

    return observedWindObj;
  }, [currentWind]);

  const hideCamPlayer = hideThumbnailOnMobile && isMobileView;

  const { hasAdFreeCamPermissions, hasAdSupportedCamPermissions } = useUserPermissionStatus();

  const playerProps = useMemo(
    () => ({
      autoplay: AutoplayOptions.AUTOPLAY_ENABLED,
      camera: camera as Camera,
      dontTrack: true,
      isFavorite: true,
      isPermitted: hasAdFreeCamPermissions,
      latestRewind: false,
      location: 'Homepage Spot Preview Card',
      pauseWhenNotVisible: true,
      playerId: `${id}-${cardIndex}`,
      playerSkin: PlayerSkins.THUMBNAIL,
      showOverlays: true,
      spotId: id,
      spotName: title,
      stopStream,
      segmentProperties: {
        location: 'Favorites Carousel',
        pageName: 'Home',
        spotId: id,
        spotName: title,
      },
    }),
    [camera, hasAdFreeCamPermissions, id, cardIndex, stopStream, title],
  );

  const showCamInsightsIcon = !!insightsCameraId;

  const isMaxWidthMobileMedium = useMaxWidthMobileMedium();

  const camPlayerComponent = useMemo(() => {
    const isFreeUserInPaywallSplit =
      !hasAdFreeCamPermissions &&
      !hasAdSupportedCamPermissions &&
      camPaywallRedesignTreatment === 'on';

    if (hideCamPlayer || sideThumbnail) return null;
    if (!camera || isFreeUserInPaywallSplit) {
      return (
        <div className={styles.camPlayerPlaceholder}>
          <SpotSatelliteWithPin
            spotName={title}
            geo={geo}
            size={isMobileView ? 'small' : 'large'}
          />
        </div>
      );
    }
    if (hasAdFreeCamPermissions && !stopStream) {
      return (
        <div className={styles.camPlayerPlaceholder} data-testid={`cam-live-${camera._id}`}>
          <CamPlayerV2 {...playerProps} />
        </div>
      );
    }
    return (
      <div className={styles.camPlayerPlaceholder} data-testid={`cam-still-${camera._id}`}>
        <CamStill imageUrl={camera.stillUrlFull} />
      </div>
    );
  }, [
    camPaywallRedesignTreatment,
    camera,
    geo,
    hasAdFreeCamPermissions,
    hasAdSupportedCamPermissions,
    hideCamPlayer,
    isMobileView,
    playerProps,
    sideThumbnail,
    stopStream,
    title,
  ]);

  const sideThumbnailComponent = useMemo(() => {
    if (!sideThumbnail) return null;
    return (
      <div className={styles.sideThumbnail}>
        {!camera ? (
          <SpotSatelliteWithPin
            spotName={title}
            geo={geo}
            size={isMobileView ? 'small' : 'large'}
          />
        ) : (
          <CamStill imageUrl={camera.stillUrlFull} />
        )}
      </div>
    );
  }, [camera, geo, isMobileView, sideThumbnail, title]);

  const contentGrid = useMemo(
    () => (
      <Stack direction="column" spacing={isObservationClarityEnabled ? 2 : 0} width="100%">
        <Stack direction="row" spacing={2} width="100%">
          <Typography
            variant={isMobileView || alwaysDisplaySmall ? 'subHeadline' : 'title3'}
            component="h4"
            className={styles.spotName}
          >
            {title}
          </Typography>
          <div className={styles.iconsWrapper}>
            {showCamInsightsIcon && !isMaxWidthMobileMedium && !showCamInsightsIconAtSmallWidth && (
              <CameraInsightsIcon />
            )}
          </div>
        </Stack>
        {(!isUserInObservationClaritySplit || !useClarityComponents) && (
          <CurrentWaveHeightAndRating
            alwaysDisplaySmall={alwaysDisplaySmall}
            size={Size.SMALL}
            conditions={currentConditions}
            isFlat={isFlat}
            units={units}
            waveHeight={currentWaveHeight}
          />
        )}
        {isObservationClarityEnabled && currentConditions && (
          <Box className={styles.waveRatingWrapper}>
            <CurrentWaveRating rating={currentConditions} />
          </Box>
        )}
        {isObservationClarityEnabled && !currentConditions && (
          <Box className={styles.emptyWaveRatingWrapper} />
        )}
        {isObservationClarityEnabled && (
          <Stack
            direction="row"
            spacing={2}
            className={styles.observations}
            data-testid="surf-height-clarity-observations"
          >
            <ConditionObservation
              clipPathIds={clipPathIds}
              iconUrl={observedSurf?.iconUrl}
              observedAt={observedSurf?.at}
              observedBy={observedSurf?.by as ConditionObservers}
              observationValue={observedSurfValue}
              size={isMobileView ? 'small' : 'large'}
              timezone={timezone}
              unit={isFlat ? '' : units?.waveHeight}
            />
            <ConditionObservation
              clipPathIds={clipPathIds}
              observedAt={observedWind.at}
              observedBy={observedWind.by}
              observationValue={observedWind.value}
              observationValueSuffix={mapDegreesCardinal(currentWind?.direction)}
              size={isMobileView ? 'small' : 'large'}
              timezone={timezone}
              unit={units?.windSpeed}
            />
          </Stack>
        )}
      </Stack>
    ),
    [
      alwaysDisplaySmall,
      clipPathIds,
      currentConditions,
      currentWaveHeight,
      currentWind?.direction,
      isFlat,
      isMaxWidthMobileMedium,
      isMobileView,
      isObservationClarityEnabled,
      isUserInObservationClaritySplit,
      observedSurf?.at,
      observedSurf?.by,
      observedSurf?.iconUrl,
      observedSurfValue,
      observedWind.at,
      observedWind.by,
      observedWind.value,
      showCamInsightsIcon,
      showCamInsightsIconAtSmallWidth,
      timezone,
      title,
      units,
      useClarityComponents,
    ],
  );

  return (
    <Card
      className={classNames({
        [styles.card]: true,
        [styles.small]: isMobileView,
        [styles.withClarity]: isObservationClarityEnabled,
        [className as string]: !!className,
      })}
      onClick={onClickHandler}
      data-testid={`spotPreviewCard-${cardIndex}`}
    >
      {camPlayerComponent}
      <div
        className={classNames(styles.camPlayerLabel, sideThumbnail && styles.sideThumbnailLabel)}
      >
        {sideThumbnailComponent}
        {href ? (
          <WavetrakLink href={href} className={styles.link}>
            {contentGrid}
          </WavetrakLink>
        ) : (
          contentGrid
        )}
      </div>
    </Card>
  );
};

export default SpotPreviewCard;
