import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import type { ConditionObservers } from 'types/observations';
import CloudflareResizedImage from 'components/CloudflareResizedImage';
import { formatDistance } from 'date-fns';
import BarGraphIcon from 'components/Icons/BarGraphIcon';
import CameraInsightsObservationIcon from 'components/Icons/CameraInsightsObservationIcon';
import WindStationIcon from 'components/Icons/WindStationIcon';
import { utcToZonedTime } from 'date-fns-tz';
import { convertToMilliseconds } from 'utils/time';

import styles from './ConditionObservationObservedBy.module.scss';

interface Props {
  clipPathIds?: {
    barGraphIcon?: string;
  };
  iconUrl?: string;
  observedAt: number | null;
  observedBy: ConditionObservers;
  timezone: string;
}

export type ConditionObservationObservedByProps = Props;

const ConditionObservationObservedBy: React.FC<Props> = ({
  clipPathIds,
  iconUrl,
  observedAt,
  observedBy,
  timezone,
}) => {
  const shouldShowObservedBadge = useMemo(() => {
    const observationTypes = ['Forecaster', 'Wind Station', 'Smart Cam'];
    return observationTypes.includes(observedBy);
  }, [observedBy]);

  const icon = useMemo(() => {
    switch (observedBy) {
      case 'Forecaster':
        if (iconUrl) {
          return (
            <Box
              className={styles.forecasterIcon}
              data-testid="condition-observation-forecaster-icon"
            >
              <CloudflareResizedImage
                src={iconUrl}
                alt="Forecaster observed"
                objectFit="cover"
                objectPosition="center"
                layout="fill"
                loading="lazy"
                placeholder={undefined}
              />
            </Box>
          );
        }
        return <BarGraphIcon clipPathId={clipPathIds?.barGraphIcon} />;
      case 'Wind Station':
        return <WindStationIcon />;
      case 'Smart Cam':
        return <CameraInsightsObservationIcon />;
      default:
        return <BarGraphIcon clipPathId={clipPathIds?.barGraphIcon} />;
    }
  }, [observedBy, iconUrl, clipPathIds]);

  const observedByFormatted = useMemo(() => {
    if (observedBy === 'LOTUS') {
      return 'LOTUS Forecast';
    }
    if (observedBy === 'GFS') {
      return 'Model Forecast';
    }
    return observedBy;
  }, [observedBy]);

  const observedAtFormatted = useMemo(() => {
    if (!shouldShowObservedBadge || !observedAt) {
      return null;
    }

    try {
      // Convert observedAt to milliseconds if it's in seconds
      const observedAtMs = convertToMilliseconds(observedAt);
      const nowMs = Date.now();

      // Use date-fns-tz to convert timestamps to the correct timezone
      const zonedObservedAt = utcToZonedTime(observedAtMs, timezone);
      const zonedNow = utcToZonedTime(nowMs, timezone);

      // Get the standard format
      let formattedTime = formatDistance(zonedObservedAt, zonedNow, {
        includeSeconds: true,
        addSuffix: true,
      });

      // Replace full time units with short versions
      formattedTime = formattedTime
        .replace('seconds', 'sec')
        .replace('second', 'sec')
        .replace('minutes', 'mins')
        .replace('minute', 'min')
        .replace('hours', 'hrs')
        .replace('hour', 'hr')
        .replace('months', 'mo')
        .replace('month', 'mo')
        .replace('years', 'yrs')
        .replace('year', 'yr');

      return formattedTime;
    } catch (error) {
      console.error('Error formatting time:', error);
      return 'recently';
    }
  }, [observedAt, shouldShowObservedBadge, timezone]);

  return (
    <Box data-testid="condition-observation-observed-by-container">
      <Typography component="div" className={styles.observedBy}>
        {icon}
        <Typography
          className={styles.observedByValue}
          component="span"
          data-testid="condition-observation-observed-by"
          variant="caption2"
        >
          {observedByFormatted}
        </Typography>
        {observedAtFormatted && (
          <>
            <Typography component="span" className={styles.separator}>
              {' \u00B7 '}
            </Typography>
            <Typography
              className={styles.observedAt}
              component="span"
              data-testid="condition-observation-observed-at"
              variant="caption2"
            >
              {observedAtFormatted}
            </Typography>
          </>
        )}
      </Typography>
    </Box>
  );
};

export default ConditionObservationObservedBy;
